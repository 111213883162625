

.ant-btn {
  font: inherit;
  border: 1px solid #1890ff;
  background: #1890ff;
  color: #fff;
  cursor: pointer;
}

.ant-btn:hover,
.ant-btn:active {
  background: #4ba9ff;
  border-color: #ffffff;
  color: #fff;
}

.ant-btn:focus {
  outline: none;
}
