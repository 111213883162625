
html {
  font-family: sans-serif;
}
html, body, #root, #map {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.map{
  height: 100vh;
  width: 100%;
}
