
  .ol-control button{ 
      background-color: #1890ff !important;
      border-color: #ffffff;
      color: white !important;
      border-radius: 50% !important;
      height: 28px  !important;
      width: 28px  !important;
  }
  .ol-control button:hover{ 
      background-color: #4ba9ff !important;
      border-color: #ffa96a !important;
      color: #ffa96a !important;
  }
  
  .ol-zoom .ol-zoom-in {
      position: fixed !important;
      top: 12px !important;
      left: 10px !important;
  }
  
  .ol-zoom .ol-zoom-out {
      position: fixed !important;
      top: 54px !important;
      left: 10px !important;
  }
  
  .ol-rotate-reset {
      position: fixed !important;
      top: 50px !important;
      right: 10px !important;
  }
  
  .ol-scale-line, .ol-scale-bar {
      bottom: 35px !important;
  }